import Vue from 'vue'
import AppDatatable from '@/components/Global/AppDatatable.vue'
import AppPagination from '@/components/Global/AppPagination.vue'
import AppFormTip from '@/components/Global/AppFormTip.vue'
import ColorChip from '@/components/Global/ColorChip.vue'
import AppItemSearch from '@/components/Global/AppItemSearch.vue'
import AppSubmitButton from '@/components/Global/AppSubmitButton.vue'
import InvoiceHeader from '@/components/Global/InvoiceHeader.vue'
import AppDatePicker from '@/components/Global/AppDatePicker.vue'
import AppBranchSelect from '@/components/Global/AppBranchSelect.vue'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'  //Globally import VTextField

Vue.component('v-text-field', VTextField)
Vue.component('app-datatable', AppDatatable)
Vue.component('app-pagination', AppPagination)
Vue.component('app-form-tip', AppFormTip)
Vue.component('color-chip', ColorChip)
Vue.component('app-item-search', AppItemSearch)
Vue.component('app-submit-button', AppSubmitButton)
Vue.component('invoice-header', InvoiceHeader)
Vue.component('app-date-picker', AppDatePicker)
Vue.component('app-branch-select', AppBranchSelect)
Vue.use(VCurrencyField, {
  locale: 'en',
  decimalLength: { min: 0, max: 3 },
  autoDecimalMode: false,
  min: null,
  max: null,
  defaultValue: null,
  valueAsInteger: false,
  allowNegative: true,
})
