import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../plugins/i18n.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/components/Home.vue'),
    meta: { title: i18n.t('Home') },
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/components/User/Users.vue'),
    meta: { title: i18n.t('Users') },
  },
  {
    path: '/currency',
    name: 'currency',
    component: () => import('@/components/Currency/Currencies.vue'),
    meta: { title: i18n.t('Currencies') },
  },
  {
    path: '/supplier',
    name: 'supplier',
    component: () => import('@/components/Supplier/Suppliers.vue'),
    meta: { title: i18n.t('Suppliers') },
  },
  {
    path: '/supplier-detail-account-statement',
    name: '/supplier-detail-account-statement',
    component: () => import('@/components/Supplier/SupplierDetailAccountStatement.vue'),
    meta: { title: i18n.t('Supplier Acc. Stmt.') },
  },
  {
    path: '/supplier-summary-account-statement',
    name: '/supplier-summary-account-statement',
    component: () => import('@/components/Supplier/SupplierSummaryAccountStatement.vue'),
    meta: { title: i18n.t('Supplier Sum. Acc. Stmt.') },
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import('@/components/Customer/Customers.vue'),
    meta: { title: i18n.t('Customers') },
  },
  {
    path: '/customer-detail-account-statement',
    name: '/customer-detail-account-statement',
    component: () => import('@/components/Customer/CustomerDetailAccountStatement.vue'),
    meta: { title: i18n.t('Customer Acc. Stmt.') },
  },
  {
    path: '/customer-summary-account-statement',
    name: '/customer-summary-account-statement',
    component: () => import('@/components/Customer/CustomerSummaryAccountStatement.vue'),
    meta: { title: i18n.t('Customer Sum. Acc. Stmt.') },
  },
  {
    path: '/item',
    name: 'item',
    component: () => import('@/components/Item/Items.vue'),
    meta: { title: i18n.t('Items') },
  },
  {
    path: '/stock',
    name: 'stock',
    component: () => import('@/components/Stock/Stock.vue'),
    meta: { title: i18n.t('Stock') },
  },
  {
    path: '/expense-type',
    name: 'expense-type',
    component: () => import('@/components/ExpenseType/ExpenseTypes.vue'),
    meta: { title: i18n.t('Expense Types') },
  },
  {
    path: '/expense',
    name: 'expense',
    component: () => import('@/components/Expense/Expenses.vue'),
    meta: { title: i18n.t('Expenses') },
  },
  {
    path: '/purchase',
    name: 'purchase',
    component: () => import('@/components/Purchase/Purchases.vue'),
    meta: { title: i18n.t('Purchases') },
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/components/Payment/Payments.vue'),
    meta: { title: i18n.t('Payments') },
  },
  {
    path: '/sale',
    name: 'sale',
    component: () => import('@/components/Sale/Sales.vue'),
    meta: { title: i18n.t('Sales') },
  },
  {
    path: '/receipt',
    name: 'receipt',
    component: () => import('@/components/Receipt/Receipts.vue'),
    meta: { title: i18n.t('Receipts') },
  },

  ///////////////////////// Reports 
  {
    path: '/report/profit',
    name: '/report/profit',
    component: () => import('@/components/Report/Profit.vue'),
    meta: { title: i18n.t('Profit') },
  },
  {
    path: '/report/qist',
    name: '/report/qist',
    component: () => import('@/components/Report/Qist.vue'),
    meta: { title: i18n.t('Qist') },
  },
  {
    path: '/report/stock-detail',
    name: '/report/stock-detail',
    component: () => import('@/components/Report/StockDetail.vue'),
    meta: { title: i18n.t('Stock Detail') },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title + ' | پێشانگای خالید' : 'پێشانگای خالید'
  next()
})

export default router
