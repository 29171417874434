<template>
  <div>
    <tr>
      <th
        colspan="100"
        class="d-none d-print-table-cell rounded py-3 px-2"
        style="border: 1px solid #000; opacity: 1; color: #000"
      >
        <table>
          <tr>
            <th
              class="text-h5 font-weight-bold pb-4"
              style="font-size: 1.8rem !important; letter-spacing: -0.7px !important"
            >
              مۆبیلیات و پێشانگای مدرید ٢
            </th>
            <th
              class="text-subtitle-1 font-weight-bold pb-4"
              style="font-size: 1.2rem !important; letter-spacing: -0.7px !important"
            >
              هەولێر - ٢٠م - شاری زیلان سیتی - نزیک مزگەوتی تەبایی
            </th>
          </tr>

          <tr>
            <th
              class="text-subtitle-1 text-start dir-ltr"
              style="font-size: 1.2rem !important;"
            >
              0750 448 4658
            </th>
            <th
              class="text-subtitle-1 text-end px-6 dir-ltr"
              style="font-size: 1.2rem !important;"
            >
              0751 494 6077
            </th>
          </tr>

          <tr>
            <th class="text-subtitle-1 text-start">
              <div class="d-inline-block grey lighten-2 px-2 rounded-sm">
                {{ $t('Date') }}: {{ $_format_date(date) }}
              </div>
            </th>
            <th class="text-subtitle-1 text-end px-6">
              {{ $t('Invoice No.') }}: {{ invoiceNo }}
            </th>
          </tr>
        </table>
      </th>
    </tr>
  </div>
</template>

<script>
export default {
  props: {
    date: { type: String, default: new Date() },
    invoiceNo: { type: String, default: null },
  },
}
</script>

<style lang="scss" scoped>
</style>